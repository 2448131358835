import { toastr } from "react-redux-toastr";
import _ from "lodash";
import moment from "moment";

export function convertUTCToLocal(mydate) {
  return moment.utc(mydate).local();
}

export function notifySuccess(msg) {
  toastr.success(msg, "");
}

export function notifyError(msg) {
  toastr.error(msg, "");
}

export function notifyWarning(msg) {
  toastr.warning(msg, "");
}

export function notifyInfo(msg) {
  toastr.info(msg, "");
}

export function randomKey() {
  return Math.ceil(Math.random() * 10000);
}

export function isLiveDone() {
  var GivenDate = "2021-10-31 23:59:59";
  var CurrentDate = new Date();
  GivenDate = new Date(GivenDate);

  if (GivenDate >= CurrentDate) {
    return false;
  }
  return true;
}

export function checkEnv() {
  return !process.env.NODE_ENV || process.env.NODE_ENV === "staging";
}

export function findUsList() {
  return [
    "Capterra",
    "Google",
    "Google Ads",
    "Instagram",
    "LinkedIn",
    "Twitter",
    "Software Advice",
    "GetApp",
    "Facebook",
    "WhatsApp",
    "Referral from a friend",
    "Reddit",
    "Others",
  ];
}
export function profession() {
  return [
    "Teacher",
    "Content Head",
    "Principal",
    "School Head Teacher",
    "Student",
    "Parent",
    "EdTech Employee",
    "Academic Advisor",
    "Curriculum Developer",
    "C-level Executive",
    "Others",
  ];
}

const MSG = {
  commonAdd: "Record added successfully",
  commonApiError: "Something went wrong. Please try again.",
};

export default MSG;
