import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Switch, Redirect, Route } from "react-router-dom";

import routes from "./routes";
import PrivateRoute from "./routes/Private";
import PublicRoute from "./routes/Public";
import { fetchUser } from "./services";
import { closeNotificationBar } from "./store/reducers/generalActions";

import NotFound from "./components/notFound";
import Maitenance from "./components/maitenance";

class App extends Component {
  state = {
    isMobile: false,
  };
  componentDidMount() {
    window.gtag(
      "set",
      "page",
      window.location.pathname + window.location.search
    );
    window.gtag("send", "pageview");

    // if (this.props.isAuthenticated) {
    //   this.props.dispatch(fetchUser());
    // }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.dispatch(closeNotificationBar());
    }
  }

  calcTime = (offset) => {
    let d = new Date();

    let utc = d.getTime() + d.getTimezoneOffset() * 60000;

    let nd = new Date(utc + 3600000 * offset);
    return nd.getHours();
    // return nd.toLocaleString();
  };

  handleMobileClose = () => {
    this.setState({
      isMobile: false,
    });
  };

  redirectToMaitenance = () => {
    const { innerWidth: width } = window;
    if (width < 800 && !sessionStorage.getItem("isMobileMsg")) {
      sessionStorage.setItem("isMobileMsg", true);
      this.setState({
        isMobile: true,
      });
    }
    if (this.props.location.pathname != "/maitenance") {
      if (process.env.REACT_APP_MAITENANCE == "true") {
        this.props.history.push("/maitenance");
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.isMobile && (
          <div className="toast-main">
            <div
              className="toast"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div className="toast-header">
                <strong className="mr-auto"></strong>
                <button
                  type="button"
                  className="ml-2 mb-1 close"
                  data-dismiss="toast"
                  aria-label="Close"
                >
                  <span aria-hidden="true" onClick={this.handleMobileClose}>
                    &times;
                  </span>
                </button>
              </div>
              <div className="toast-body">
                PrepAI works best on desktop/laptop. See you there!👋
              </div>
            </div>
          </div>
        )}
        <Switch>
          <Route path="/maitenance" component={Maitenance} />
          {this.redirectToMaitenance()}
          {/*{this.calcTime("+5.5") >= 20 && <Redirect to="/maitenance" />} */}
          {/*{this.calcTime("+5.5") < 10 && <Redirect to="/maitenance" />} */}
          {routes.map((route, i) => {
            if (route.auth) {
              return <PrivateRoute key={i} {...route} />;
            }
            return <PublicRoute key={i} {...route} />;
          })}

          <Route path="/404" component={NotFound} />
          <Redirect from="/" to="/generate-questions" />
          <Redirect to="/404" />
        </Switch>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    refreshing: state.generalActions.refreshing,
  };
};

export default withRouter(connect(mapStateToProps)(App));
