import Http from "./utils/Http";
import { setUserData, authLogout } from "./store/reducers/auth";
import { notifyError } from "./utils/Helper";

/**
 * fetch the current logged in user
 *
 * @returns {function(*)}
 */

export function loginUser(params) {
  return Http.post("login", params);
}

export function referUser(params) {
  return Http.post("referuser", params);
}

export function getReferredBalance(params) {
  return Http.get("getReferralBalance", params);
}

export function registerUser(params) {
  return Http.post("registration", params);
}

export function changePassword(params) {
  return Http.post("changePassword", params);
}

export function forgotPasswordUser(params) {
  return Http.post("forgetPassword", params);
}

export function checkValidToken(params) {
  return Http.post("checkTokenAvailability", params);
}

export function resetPassword(params) {
  return Http.post("resetPassword", params);
}

export function updateProfile(params) {
  return Http.post("updateProfile", params);
}

export function fetchUser() {
  return (dispatch) => {
    return Http.get("me")
      .then((res) => {
        const data = res.data.response;
        // console.log(data);
        dispatch(setUserData(data));
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          const { data } = err.response;
          notifyError(data.msg);
          dispatch(authLogout());
        }
        if (err.response && err.response.status === 401) {
          const { data } = err.response;
          // notifyError(data.msg);
          dispatch(authLogout());
        }
        // console.log(err);
      });
  };
}

export function generateQuestion(params) {
  // return Http.post("generateQuestions", params);
  return Http.post("generateQuestions", params, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function updateQuestionRating(params) {
  return Http.post("storeQuestionRating", params);
}

export function updateOptionRating(params) {
  return Http.post("storeOptionRating", params);
}

export function updateIsRequired(params) {
  return Http.post("storeIsRequired", params);
}

export function uploadFile(params) {
  return Http.post("Chat/SendFiles", params, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
}

export function generateQuestionFromVideo(params) {
  return Http.post("getTranscripts", params);
}

export function wikiTopicSuggestions(params) {
  return Http.post("wiki", params);
}

export function getMyQuestionPaper(params) {
  return Http.post("getMyQuestionPaper", params);
}

export function previewQuestions(params) {
  return Http.post("btPreviewQuestions", params);
}

export function downloadQuestionPaper(params) {
  return Http.post("btDownloadQuestionPaper", params, {
    responseType: "blob",
  });
}

export function finalizeQuestions(params) {
  return Http.post("finalizeQuestions", params);
}

export function editQuestionPaper(params) {
  return Http.post("editQuestions", params);
}

export function getPlansList() {
  return Http.get("planList");
}

export function getCountry() {
  return Http.get("getCountry");
}

export function getUserPlanHistory() {
  return Http.get("btUserPlanHistory");
}

export function generateOrderIdRazorpay(params) {
  return Http.post("generateOrderId", params);
}

export function verifyPaymentRazorpay(params) {
  return Http.post("verifyPayment", params);
}

export function verifyAccountCode(params) {
  return Http.post("verifyCode", params);
}

export function verifyResendAccountCode() {
  return Http.get("resendCode");
}

export function updateInstructionRead() {
  return Http.post("instructionReaded");
}

export function generateQuestionFromPdfDoc(params) {
  return Http.post("generateQuestionsFromPdfDoc", params);
}

export function getQPRating(params) {
  return Http.get("getQPRating");
}

export function QuestionPaperRating(params) {
  return Http.post("btQuestionPaperRating", params);
}

export function EnterpriseEnquiry(params) {
  return Http.post("enterpriseEnquiry", params);
}

export function SaveEditQuestion(params) {
  return Http.post("saveEditQuestion", params);
}

export function getNotificationList(params) {
  return Http.get("getNotificationList?is_taxonomy=true");
}

export function validationForDownloadQuePaper(params) {
  return Http.post("validationForDownloadQuePaper", params);
}

export function createPaymentIntent(params) {
  return Http.post("createPaymentIntent", params);
}

export function setPaymentIntent(params) {
  return Http.post("setPaymentIntent", params);
}

export function createSubscription(params) {
  return Http.post("createSubscription", params);
}

export function addPagination(params) {
  return Http.post("pagination", params);
}

export function addQuestion(params) {
  return Http.post("addQuestion", params);
}

export function cancelSubscription() {
  return Http.post("cancelSubscription");
}

export function listSubscription() {
  return Http.get("listSubscriptions");
}

export function storeBilllingAddress(params) {
  return Http.post("storeBilllingAddress", params);
}

export function getFeatureRequests() {
  return Http.get("feature-requests");
}

export function addFeatureRequest(params) {
  return Http.post("feature-requests", params);
}

export function updateVote(params) {
  return Http.post("votes", params);
}

export function getVotes(id) {
  return Http.get(`votes?id=${id}`);
}

export function registerExternalUser(params) {
  return Http.post("registerExternalUser", params);
}

export function verifyExternalUser(params) {
  return Http.post("verifyExternalUser", params);
}

export function getAnswerKeys(id) {
  return Http.get(`bt-answer-key?content_id=${id}`, {
    responseType: "blob",
  });
}
export function storeStripePaymentData(params) {
  return Http.post("storeStripePaymentData", params);
}

export function activatePlan(params) {
  return Http.post("activatePlan", params);
}

export function scheduleCallStatus(parms = true) {
  if (parms) {
    return Http.get("scheduleCallStatus");
  } else {
    return Http.post("scheduleCallStatus");
  }
}

export function updateUserDetails(params) {
  return Http.post("updateUserDetails", params);
}

export function GQBloomTaxanomy(params) {
  return Http.post("GQBloomTaxanomy", params);
}

export function GQSearchTopic(params) {
  return Http.post("btSearchTopic", params);
}

export function BuyCredits(params) {
  return Http.post("buyCredits", params);
}

export function btUserLogin(params) {
  return Http.post("btUserLogin", params);
}
