import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";

import Header from "./../components/header";
import Footer from "./../components/footer";
import LoaderCircle from "./../components/loader-circle";

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  refreshing,
  user,
  location,
  ...rest
}) => {
  if (location.search) {
    const parsed = queryString.parse(location.search);
    if (parsed.doredirect == "true" && !isAuthenticated) {
      localStorage.setItem("refData", location.search);
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <LoaderCircle isLoading={refreshing} />
            {isAuthenticated &&
              user.is_verified != undefined &&
              !user.is_verified && <Redirect to="/verification-required" />}
            {isAuthenticated ? (
              <main>
                <Header />
                <Component {...props} />
                <Footer />
              </main>
            ) : (
              <Redirect
                to={{
                  pathname: "/sso",
                  state: { from: props.location },
                }}
              />
            )}
          </Suspense>
        );
      }}
    />
  );
};

PrivateRoute.displayName = "Private Routes";

PrivateRoute.propTypes = {
  component: PropTypes.object.isRequired,
  location: PropTypes.object,
  isAuthenticated: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

// Retrieve data from store as props
function mapStateToProps(store) {
  return {
    isAuthenticated: store.auth.isAuthenticated,
    refreshing: store.generalActions.refreshing,
    user: store.auth.user,
  };
}

export default withRouter(connect(mapStateToProps)(PrivateRoute));
