import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import FeatureRequest from "./FeatureRequest";
import Updates from "./Updates";
import { closeNotificationBar } from "./../../store/reducers/generalActions";

class NotificationBar extends Component {
  state = {
    notificationList: [],
    showUpdates: true,
    newPosts: true,
  };

  handleNotifyView = (view) => {
    this.setState({
      showUpdates: view === "updates" ? true : false,
      newPosts: false,
    });
  };

  // async componentDidMount() {
  //   this.props.dispatch(startLoader());
  //   try {
  //     const { data: res } = await getNotificationList();
  //     if (res.success == true) {
  //       this.setState({
  //         notificationList: [...res.response],
  //       });
  //       this.props.dispatch(resetNotificationCount());
  //     } /*else {
  //       notifyError(res.message);
  //     } */
  //   } catch (err) {
  //     notifyError(MSG.commonApiError);
  //   }
  //   this.props.dispatch(stopLoader());
  // }

  render() {
    return (
      <div className="beamerLoader">
        <button
          type="button"
          class="close notify_close"
          aria-label="Close"
          style={{ outline: "none" }}
          onClick={() => this.props.dispatch(closeNotificationBar())}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="header">
          <div
            className={`header_option ${
              this.state.showUpdates ? "header_option-active" : ""
            }`}
          >
            <h5
              className={`notify-link ${
                this.state.showUpdates ? "notify-active" : ""
              }`}
              onClick={() => this.handleNotifyView("updates")}
            >
              Updates
            </h5>
          </div>

          <div
            className={`header_option requests ${
              !this.state.showUpdates ? "header_option-active" : ""
            }`}
          >
            <h5
              className={`notify-link ${
                !this.state.showUpdates ? "notify-active" : ""
              }`}
              onClick={() => this.handleNotifyView("")}
            >
              Feature Requests{" "}
            </h5>
            {this.state.newPosts && <span className="newer_requests">New</span>}
          </div>
          {/* Close Button */}
          {/* <span
            className="close-btn"
            onClick={() => this.props.dispatch(closeNotificationBar())}
          >
            <i className="fa fa-times"></i>
          </span> */}
        </div>

        {/* {this.state.notificationList.length != 0 ? (
          this.state.notificationList.map((item) => (
            <div
              className={`notif-content ${
                item.is_new_notify == 1 ? "notify-tile-color" : ""
              }`}
              key={randomKey()}
            >
              <span className="notif-header">
                <b>{item.notif_title}</b>
              </span>
              <br />
              <div className="notif-msg" key={randomKey()}>
                <span>{item.notif_msg}</span>
              </div>
              <span className="notif-date">
                {moment.utc(item.created_at).local().format("LLL")}
              </span>
            </div>
          ))
        ) : (
          <div>
            <div className="no-notify-found">
              <span><i className="fas fa-bell bell"></i></span>
            </div>
            <div style={{ marginLeft: "95px", marginTop:"5px" }}>
              <h5>You’re all caught up</h5>
            </div>
          </div>
        )} */}

        {this.state.showUpdates && <Updates />}
        {!this.state.showUpdates && <FeatureRequest />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.isAuthenticated,
  };
};

export default connect(mapStateToProps)(NotificationBar);
