// import lib
import { lazy } from "react";

const webRoutes = [
  {
    path: "/question-paper",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "dashboard" */ "./../components/question-paper"
      )
    ),
  },
  {
    path: "/sso",
    exact: true,
    component: lazy(() =>
      import(/* webpackChunkName: "login" */ "./../components/sso")
    ),
  },
  // {
  //   path: "/login",
  //   exact: true,
  //   component: lazy(() =>
  //     import(/* webpackChunkName: "login" */ "./../components/login")
  //   ),
  // },
  {
    path: "/verification-required",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "verification.js" */ "./../components/verification"
      )
    ),
  },
  {
    path: "/edit-profile",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "editProfile" */ "./../components/edit-profile"
      )
    ),
  },
  {
    path: "/my-account",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(/* webpackChunkName: "myAccount" */ "./../components/my-account")
    ),
  },
  {
    path: "/plans",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(/* webpackChunkName: "PlansList" */ "./../components/plans-list")
    ),
  },
  {
    path: "/change-password",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "changePassword" */ "./../components/change-password"
      )
    ),
  },
  {
    path: "/generate-questions",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperGenerate" */ "./../components/question-paper-generate"
      )
    ),
  },
  {
    path: "/question-paper-preview",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "questionPaperPreview" */ "./../components/question-paper-preview"
      )
    ),
  },
  {
    path: "/refer-prepai",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "referPrepai" */ "./../components/refer-prepai"
      )
    ),
  },
  {
    path: "/get-free-gold-plan",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "getFreeGoldPlan" */ "./../components/get-free-gold-plan"
      )
    ),
  },
  {
    path: "/referral-code",
    exact: true,
    auth: true,
    component: lazy(() =>
      import(
        /* webpackChunkName: "verifyAppsumoAccount" */ "./../components/referral-code"
      )
    ),
  },
];

export default webRoutes;
