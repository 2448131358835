import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import {
  showNotificationBar,
  closeNotificationBar,
} from "./../store/reducers/generalActions";
import NotificationBar from "./common/notificationBar";
import SweetAlert from "react-bootstrap-sweetalert";
import freeImage from "./../free-morph-outline.gif";
class Header extends Component {
  state = {
    refreshing: true,
    showAlert: false,
    showReferral: false,
    referralEmail: "",
    referralMessage: "",
    showBadge: true,
    app_url: "https://app.prepai.io/generate-questions",
  };

  componentDidMount() {
    this.setState({
      refreshing: false,
      showReferral: false,
    });
  }

  handleLogout = () => {
    localStorage.removeItem("access_token");
    window.location = `${process.env.REACT_APP_PREPAI_APP_URL}/generate-questions`;
    // window.location.reload();
    // this.props.history.replace("/login");
  };

  handleNotification = () => {
    if (this.props.notificationBarShow) {
      this.props.dispatch(closeNotificationBar());
    } else {
      this.props.dispatch(showNotificationBar());
      this.setState({ showBadge: false });
    }
  };

  // handleReferralToOpen = () => {
  //   this.setState({
  //     showReferral: true,
  //   });
  // };

  // handleReferralToClose = () => {
  //   this.setState({
  //     showReferral: false,
  //   });
  // };

  showHeaderTextPlan = () => {
    if (this.props.user.plan_details) {
      var planButtonText = "Buy Credits";

      if (this.props.user.plan_details.plan_name == "Yearly") {
        var planButtonText = "Buy Credits";
      }
      return (
        <React.Fragment>
          <React.Fragment>
            <li className="nav-item">
              {/* Get the most of PrepAI with{" "}
                <Link className="nav-link text-success-prep ml-1" to="/plans">
                  Premium Plans
                </Link> */}
              <Link className="btn upgrade-plan-btn" to="/plans">
                {planButtonText}
              </Link>
            </li>
          </React.Fragment>
        </React.Fragment>
      );
    }
  };

  showHeaderTextFreePlan = () => {
    if (this.props.user.plan_details) {
      return (
        <React.Fragment>
          {this.props.user.plan_details.plan_name == "Free" ? (
            <React.Fragment>
              <li
                className="nav-item"
                style={{ position: "absolute", top: 0, right: "10px" }}
              >
                <span className="btn btn-main btn-sm disabled">Free Plan</span>
              </li>
            </React.Fragment>
          ) : (
            <li
              className="nav-item"
              style={{ position: "absolute", top: 0, right: "10px" }}
            >
              <span
                className={`btn btn-${this.props.user.plan_details.plan_name} btn-sm`}
              >
                {this.props.user.plan_details.plan_name} Plan
              </span>
            </li>
          )}
        </React.Fragment>
      );
    }
  };

  showUserPlan = () => {
    if (this.props.user.plan_details) {
      return (
        <React.Fragment>
          <span className="beta">BETA</span>
          {/*{this.props.user.plan_details.plan_name == "Free" ? (
            <span className="basic">Basic</span>
          ) : (
            <span className="premium">Premium</span>
          )} */}
        </React.Fragment>
      );
    }
  };

  render() {
    return (
      <React.Fragment>
        {/*<Tooltip title="Coming Soon" id="global" /> */}
        <div className="custom-navbar">
          <nav className="navbar navbar-expand-md">
            <div
              className="back-btn"
              onClick={() =>
                (window.location = `${process.env.REACT_APP_PREPAI_APP_URL}/generate-questions`)
              }
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-arrow-left" aria-hidden="true"></i>
            </div>
            {/* </Link> */}
            <div className="container">
              <div className="style-logo">
                <Link className="navbar-brand" to="/generate-questions">
                  <img
                    src={`${process.env.REACT_APP_PROJECT_URL}/img/prepAI-new-logo.svg`}
                    alt="prepAi-logo"
                    style={{ width: "120px" }}
                  />
                </Link>
              </div>
              {this.showUserPlan()}
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse custom-navlinks"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  {this.props.user.credits != undefined && (
                    <li className="nav-item" style={{ marginTop: "10px" }}>
                      <div className="Available-Credits" id="Available-Credits">
                        Available Credits:{" "}
                        <b>
                          {this.props.user.credits == -1
                            ? 0
                            : this.props.user.credits}
                        </b>
                        <span className="CreditsInfo">
                          2 PrepAI Credits = 1 Question Paper Generation
                        </span>
                      </div>
                    </li>
                  )}
                  {this.showHeaderTextPlan()}
                  {/* <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/refer-prepai"
                      style={{ marginTop: "-8px" }}
                    >
                      <img
                        src={freeImage}
                        width="60"
                        height="60"
                        alt="gif_loading"
                      ></img>
                    </Link>
                  </li> */}
                  <li className="nav-item">
                    {this.props.location.pathname != "/question-paper" ? (
                      <Link
                        className="nav-link"
                        to="/question-paper"
                        style={{ marginTop: "10px" }}
                      >
                        My Question Papers
                      </Link>
                    ) : (
                      <Link
                        className="nav-link"
                        to="/generate-questions"
                        style={{ marginTop: "10px" }}
                      >
                        Generate Question Papers
                      </Link>
                    )}
                  </li>
                  <li className="nav-item">
                    <div className="notify-tool-tip">
                      <i
                        className="fas fa-info-circle instructions"
                        onClick={() => this.setState({ showAlert: true })}
                      ></i>
                      <span className="hoverText">Instructions</span>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div className="notify-tool-tip" id="notify-bell">
                      {/* {this.state.showBadge && <span className="notify-new-icon"> New </span>} */}
                      <i
                        className="far fa-bell notification"
                        onClick={this.handleNotification}
                      ></i>
                      {/* <span className="hoverText">Notifications</span> */}
                    </div>
                    {this.props.user.new_notify_count > 0 && (
                      <span className="position-absolute rounded-pill badge style-badge text-white">
                        {this.props.user.new_notify_count}
                      </span>
                    )}
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{ marginTop: "10px" }}
                    >
                      <span className="dinline-block mr-2">
                        <svg
                          width="14"
                          height="18"
                          viewBox="0 0 14 18"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.4118 5.07588C11.4118 7.52341 9.44941 9.48593 7.00016 9.48593C4.55174 9.48593 2.5885 7.52341 2.5885 5.07588C2.5885 2.62836 4.55174 0.666672 7.00016 0.666672C9.44941 0.666672 11.4118 2.62836 11.4118 5.07588ZM7.00016 17.3333C3.38547 17.3333 0.333496 16.7458 0.333496 14.4791C0.333496 12.2116 3.40465 11.6449 7.00016 11.6449C10.6157 11.6449 13.6668 12.2325 13.6668 14.4991C13.6668 16.7667 10.5957 17.3333 7.00016 17.3333Z"
                            fill="#526892"
                          />
                        </svg>
                      </span>
                      {this.props.user.name}
                      <span className="ml-1 arr-stroke">
                        <svg
                          width="15"
                          height="9"
                          viewBox="0 0 15 9"
                          fill="none"
                        >
                          <path
                            d="M14 1L7.5 8L1 1"
                            stroke="#526892"
                            strokeLinecap="square"
                          />
                        </svg>
                      </span>
                    </a>
                    <div
                      className="dropdown-menu navdrop--menu"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link className="dropdown-item" to="/my-account">
                        My Account
                      </Link>
                      <Link className="dropdown-item" to="/edit-profile">
                        Edit Profile
                      </Link>
                      <Link className="dropdown-item" to="/change-password">
                        Change Password
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="#"
                        onClick={this.handleLogout}
                      >
                        Log out
                      </Link>
                    </div>
                  </li>
                  {/*{this.showHeaderTextFreePlan()}*/}
                </ul>
              </div>
            </div>
          </nav>
        </div>

        <SweetAlert
          customClass="sweet-alert"
          show={this.state.showAlert}
          showConfirm
          title={
            <div className="swal-title">
              <h2>Please Read Carefully</h2>
              <hr />
              <ol>
                <li className="instruction-points">
                  PrepAI now supports Subjective as well as Non-Subjective
                  content like Mathematical Equations, Chemical Reactions,
                  Numericals, etc. as well.
                </li>
                <li className="instruction-points">
                  PrepAI generates quality questions on quality content. It may
                  not produce accurate results in cases where content is not
                  structured, or contains tabular data, diagrams, etc.
                </li>
                <li className="instruction-points">
                  PrepAI is available for content in English Language.
                </li>
              </ol>
            </div>
          }
          confirmBtnText="OK"
          confirmBtnStyle={{ fontSize: "16px" }}
          confirmBtnBsStyle="success"
          onEscapeKey={() => this.setState({ showAlert: false })}
          onOutsideClick={() => this.setState({ showAlert: false })}
          onConfirm={() => this.setState({ showAlert: false })}
          onCancel={() => this.setState({ showAlert: false })}
        />

        {this.props.notificationBarShow && <NotificationBar />}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    notificationBarShow: state.generalActions.notificationBarShow,
    user: state.auth.user,
  };
};
export default withRouter(connect(mapStateToProps)(Header));
