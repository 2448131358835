import React from "react";

const LoaderCircle = ({ isLoading }) => {
  if (isLoading) {
    return (
      <div id="smloadingDiv">
        <div className="smloader">Loading...</div>
      </div>
    );
  }
  return "";
};

export default LoaderCircle;
