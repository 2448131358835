import { Component } from 'react';
import {ThumbUpAltOutlined, ThumbDownAltOutlined, ThumbDownAlt, ThumbUpAlt} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { notifyError, notifySuccess } from '../../utils/Helper';
import { updateVote, getVotes } from '../../services';

export default class Feature extends Component {

    constructor(props) {
        super(props);

        this.state = {
            likeActive: false,
            dislikeActive: false,
            likes: this.props.likes,
            dislikes: this.props.dislikes
        }

    }


    setDislike = () => {
        this.setState({
            dislikeActive: !this.state.dislikeActive,
            dislikes: this.state.dislikeActive ? this.state.dislikes - 1 : this.state.dislikes + 1
        })
    }


    setLike = () => {
        this.setState({
            likeActive: !this.state.likeActive,
            likes: this.state.likeActive ? this.state.likes - 1 : this.state.likes + 1
        })
    }


    handleLike = async (id) => { 
        try{

            const {data} = await updateVote({
                id: id,
                like: true
            });

            // const fetchSuccess = await this.props.getRequests();

            if(data.success) {
                if(this.state.dislikeActive){
                    this.setLike();
                    this.setDislike();
                }
                this.setLike();
                notifySuccess(data.message)
            }
                
        } catch(e){
            console.error(e.message);
            notifyError(e.message);
        }   
        
	}

    handleDislike = async (id) => {
        
        try{

            const {data} = await updateVote({
                id: id,
                dislike: true
            });

            // const fetchSuccess = await this.props.getRequests();

            if(data.success) {
                if(this.state.likeActive){
                    this.setDislike();
                    this.setLike();
                }
                this.setDislike();
                notifySuccess(data.message)
            }
        } catch(e){
            console.error(e.message);
            notifyError(e.message);
        }

    } 

    async componentDidMount(){

        try {

			const {data} = await getVotes(this.props.id);
			if(data.success){
				this.setState({likeActive: data.response.liked, dislikeActive: data.response.disliked});
			} else{
				notifyError(data.message);
			}

		} catch(e){
			console.error(e);
			notifyError(e.message);
		}

    }


	titleFormat = (val) => {
		return val.charAt(0).toUpperCase() + val.slice(1, val.length);
	}

	descriptionFormat = (val) => {
		return val.slice(0, 150) + "...";
	}

	featureStatusClass = (id) => {
		switch(id){
			case 0:
				return "pending_circle"
			
			case 1:
				return "progress_circle"
			
			case 2:
				return "completed_circle"
		}

	}

	featureStatusText = (id) => {
		switch(id){
			case 0:
				return "PENDING"
			
			case 1:
				return "IN PROGRESS"
			
			case 2:
				return "COMPLETED"
		}
	}


    render() {
        return (
            <div className="feature_requestBox">
                <div className="feature_votes">
                    <div className="votes">
                        <IconButton style={{ outline: "none" }} onClick={() => this.handleLike(this.props.id)}>
                            {this.state.likeActive ? <ThumbUpAlt style={{color: "#1da75b"}} /> : <ThumbUpAltOutlined />}
                        </IconButton>
                        <p className="feature__num" > {this.state.likes} </p>
                    </div>

                    <div className='votes'>
                        <IconButton style={{ outline: "none" }} onClick={() => this.handleDislike(this.props.id)}>
                            {this.state.dislikeActive ? <ThumbDownAlt style={{color: "#ce2b1f"}} /> : <ThumbDownAltOutlined />}
                        </IconButton>
                        <p className="feature__num"> {this.state.dislikes} </p>
                    </div>

                </div>
                <div className="feature_body">
                    <div className="feature_header">
                        <h6> {this.titleFormat(this.props.title)} </h6>
                        <h6 className="feature__text"> <span className={`circle ${this.featureStatusClass(this.props.feature_status)}`}></span> {this.featureStatusText(this.props.feature_status)} </h6>
                    </div>

                    <div className="feature__content">
                        <p className="feature__text"> {this.descriptionFormat(this.props.description)} </p>
                    </div>

                </div>
            </div>
        )
    }
}
